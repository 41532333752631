<template>
  <div class="card card-xxl-stretch mb-xl-3">
    <div class="card-header border-0">
      <h3 class="card-title fw-bolder text-dark">
        {{ $t("permissionsIndex") }}
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body pt-2">
      <template v-for="item in items" :key="item.id">
        <div class="mb-8 d-flex align-items-center">
          <span
            :class="
              `bullet bullet-vertical h-40px bg-${
                colors[Math.floor(Math.random() * colors.length)]
              }`
            "
          ></span>
          <div class="flex-grow-1 mx-5">
            <a
              href="javascript:void(0)"
              class="text-gray-800 text-hover-primary fw-bolder fs-6"
              >{{ item.name }}
            </a>
          </div>
          <el-switch
            v-model="item.hasPermission"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "@vue/runtime-core";

export default defineComponent({
  name: "PermissionsList",
  props: {
    items: Array
  },
  setup() {
    return {
      colors: ref(["success", "info", "primary", "warning", "danger"])
    };
  }
});
</script>
