import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mx-xl-12" }
const _hoisted_2 = { class: "d-sm-flex flex-row justify-content-between mb-6" }
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = { class: "text-darker mb-0" }
const _hoisted_5 = { class: "text-gray-500 fs-5 fw-bold" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "me-auto p-1" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = {
  class: "row",
  style: {"display":"none"}
}
const _hoisted_10 = { class: "col-md-6" }
const _hoisted_11 = { class: "col-md-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_table_basic = _resolveComponent("table-basic")!
  const _component_UsersgroupList = _resolveComponent("UsersgroupList")!
  const _component_PermissionsList = _resolveComponent("PermissionsList")!
  const _component_UsersGroupCU = _resolveComponent("UsersGroupCU")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("permissions")), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.dataObject.length) + " " + _toDisplayString(_ctx.$t("ielements")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_select, {
            modelValue: _ctx.role_selected,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.role_selected) = $event)),
            name: "port",
            class: "form-select-custom w-sm-300px",
            clearable: "",
            placeholder: `${_ctx.$t('iselect')}`,
            filterable: "",
            style: {"height":"48px"},
            remote: "",
            "reserve-keyword": "",
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkPermissions(_ctx.role_selected)))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (port) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: port.id,
                  label: port.name,
                  value: port.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(`${port.id} ${port.name}`), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder"]),
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
              type: "text",
              class: "form-control form-control-search w-sm-300px",
              placeholder: `${_ctx.$t('isearch')}`,
              onKeyup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)))
            }, null, 40, _hoisted_8), [
              [_vModelText, _ctx.search]
            ])
          ]),
          _createElementVNode("button", {
            class: "btn btn-custom btn-app-custom",
            "data-bs-toggle": "modal",
            "data-bs-target": "#kt_modal_1",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.createElement && _ctx.createElement(...args)))
          }, _toDisplayString(_ctx.$t("btnNew")), 1)
        ])
      ]),
      _createVNode(_component_table_basic, {
        class: "mt-5",
        loading: _ctx.isLoading,
        headers: _ctx.header,
        data: _ctx.dataObject,
        buttons: _ctx.tableButtons,
        total: _ctx.dataObject.length,
        onEventEmitButton: _ctx.actionsButton
      }, null, 8, ["loading", "headers", "data", "buttons", "total", "onEventEmitButton"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_UsersgroupList, {
          items: _ctx.roles,
          onAction: _ctx.onEvent
        }, null, 8, ["items", "onAction"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_PermissionsList, { items: _ctx.permissions }, null, 8, ["items"])
      ])
    ]),
    (_ctx.dialog.typeEvent !== '' && _ctx.dialog.typeEvent !== 'delete')
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          modelValue: _ctx.dialog.visible,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialog.visible) = $event)),
          width: "50%",
          onClose: _ctx.resetModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UsersGroupCU, {
              "type-event": _ctx.dialog.typeEvent,
              item: _ctx.element,
              onSuccess: _ctx.successOperation
            }, null, 8, ["type-event", "item", "onSuccess"])
          ]),
          _: 1
        }, 8, ["modelValue", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}