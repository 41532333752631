
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, onMounted } from "vue";
import UsersgroupList from "@/components/cards/UsersgroupList.vue";
import UsersGroupCU from "./UsersGroupCU.vue";
import PermissionsList from "@/components/cards/PermissionsList.vue";
import TableBasic from "@/components/tables/TableBasic.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ManagementUsersGroup",
  components: {
    TableBasic,
    UsersgroupList,
    UsersGroupCU,
    PermissionsList,
  },
  setup() {
    const { t } = useI18n();
    const header = ref([
      { column: t("iname"), key: "name" },
    ]);
    const role_selected = ref(1);
    const isLoading = ref(true);
    const itemID = ref("");
    const modalMode = ref("add");
    const dataList = ref([]);
    const dataObject = ref({});
    const tableButtons = ref([]);
    const search = ref("");
    const roles = ref<Array<any>>([]);
    const permissions = ref<Array<any>>([]);
    const element = ref({});
    const dialog = ref({
      ref: "",
      typeEvent: "",
      visible: false,
    });
    const actionsButton = (param1, param2) => {
      if (param2 == "EDIT") {
        dialog.value.visible = true;
        itemID.value = param1;
        modalMode.value = "edit";
      }
    };
    const createElement = () => {
      dialog.value.visible = true;
      itemID.value = "";
      modalMode.value = "add";
    };
    const onSearch = () => {
      dataObject.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };
    const getRoles = () =>
      ApiService.get("/api/roles").then(({ data }) => {
        if (data.length > 0) {
          role_selected.value = data[0].id;
          checkPermissions(data[0].id);
        }
        return data;
      });

    const getPermissions = () =>
      ApiService.get("/api/permissions").then(({ data }) => {
        return data.map((p: any) => {
          p.hasPermission = false;
          return p;
        });
      });

    onMounted(async () => {
      setCurrentPageBreadcrumbs("usersgroupIndex", ["user"], false);
      roles.value = await getRoles();
      permissions.value = await getPermissions();
    });

    // eslint-disable-next-line no-undef
    const checkPermissions = async (rol_id: BigInteger) => {
      try {
        const role = (await ApiService.get(`/api/roles/${rol_id}`)).data.permissions_list;
        dataObject.value = role;
        isLoading.value = false;
      } catch (error) {
        console.error(error);
      }
    };

    const onEvent = (item: any = {}) => {
      if (item.event === "view") {
        checkPermissions(item.data.id);
        return;
      }
      dialog.value = {
        ref: item.ref,
        typeEvent: item.event,
        visible: true,
      };
      if (item.data) {
        element.value = item.data;
      }
    };

    const resetModal = () => {
      dialog.value = {
        ref: "",
        typeEvent: "",
        visible: false,
      };
      element.value = {};
    };
    const successOperation = (response) => {
      if (dialog.value.ref === "usersgroup") {
        const index = roles.value.findIndex(
          (el: Record<string, any>) => el.id === response.id
        );
        if (index >= 0) {
          roles.value[index] = response;
        } else {
          roles.value.push(response);
        }
      }

      resetModal();
    };

    return {
      dialog,
      element,
      roles,
      permissions,
      onEvent,
      successOperation,
      resetModal,
      onSearch,
      search,
      tableButtons,
      dataObject,
      dataList,
      createElement,
      modalMode,
      isLoading,
      header,
      actionsButton,
      role_selected,
      checkPermissions,
    };
  },
});
